import React, { useState }from 'react';
import useSession from '../../useSession';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import './AgreeTerms.css';
import { SaveTermsRead } from '../Helpers/Helpers';

export default function AgreeTerms() {

    const { sessionItem } = useSession(); 
    const [sbopen, setSbOpen] = useState(false);
    const [opMessage, setOpMessage] = useState("");  

    const [pagevalue, setPageValue] = useState("");
    // const pages = "17";
    // new 2024/2025
    // get runtime pdf page count from API
    // it should be in sessionItem.form.terms_page_count
    // value from Api is a number so we must convert it to string
    const pages = sessionItem.form.terms_page_count.toString();    

    function onChange(evt) {       
        setPageValue(evt.target.value);        
    }

    function ValidatePageCount() {        
        if (pagevalue === pages) {
            // set local storage
            // for testing let's use 15 minutes
            // 30 days is 43200 minutes
            SaveTermsRead(sessionItem.film.filmObjId, 43200);
            // redirect to the home page            
            window.location.href = '/';
            
        } else {
            // display error
            setOpMessage("Invalid number of pages. The value entered is not correct.");
            setSbOpen(true);           
        }
    }

    function handleSnackBarClose() {       
        setOpMessage("");
        setSbOpen(false);       
    }

    const sbaction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );    

    return (
        <Box sx={{ minWidth: 800, bgcolor: '#F0F0F0', p: 1}}>
            <h2>Invitation for the film: {sessionItem.film.engTitle}</h2>            
            <Box>
                <article>
                    <h2>ACCEPTANCE OF INVITATION</h2>
                    <p>
                        Upon clicking "Accept Invitation," you will be directed to a series of questions
                        that must be completed to confirm your participation in the Toronto International
                        Film Festival. The Online Registration Form for a Selected Film must be completed
                        within 48 hours of receiving the Film Invitation Package.
                    </p>
                    <p>
                        To notify us of any changes after you have completed the online invitation form, please contact&nbsp;
                        <a className="lightblue" href="mailto:festivalinvitations@tiff.net?subject=Change Request" rel="noreferrer" target="_blank">festivalinvitations@tiff.net</a>.
                    </p>              
                </article>
                <article>
                    <h2>SAFETY & RESPECT AT TIFF</h2>
                    <p>
                        A cornerstone of TIFF's belief system is that everyone has the right to respect, as well as fair
                        and equitable treatment from others. TIFF does not tolerate violence, mistreatment, or harassment &#8212; verbal,
                        physical, sexual, or otherwise &#8212; towards staff, volunteers, delegates, audience members, or attendees at
                        any of our festivals, conferences, or events. Our staff and volunteers are committed to ensuring the
                        best possible experience for all of our attendees. If the safety of our guests is in question, we will
                        take immediate steps to ensure the matter is addressed.
                    </p>                   
                </article>
                <iframe id="TnCFrame" title="pdfdocframe" src="/pdf/FestivalTermsAndConditions.pdf" scrolling="yes" height="400" > </iframe>
                <article>                   
                    <div>
                        <div>
                            <b>Please verify you have read the <a className="lightblue" href="/pdf/FestivalTermsAndConditions.pdf" target="_blank"> Terms &amp; Conditions </a> by entering the page number from the final page (at the bottom right-hand corner) of the Terms &amp; Conditions and click "Accept Invitation."</b>
                        </div>
                        <div>
                            <TextField
                                id={"pageinput"}
                                name={"pageinput"}
                                required                               
                                value={pagevalue}
                                inputProps={{ maxLength: 2 }}
                                onChange={onChange}
                                size="small"
                                sx={{bgcolor:'#ffffff', marginTop:1, marginBottom:1, width:100}}
                            />
                        </div>
                        <div>
                            <Button variant="contained" sx={{ marginBottom: 1}} name="addrolebt" onClick={ValidatePageCount} >
                                Accept Invitation
                            </Button>
                        </div>
                        <div>
                            It is recommended to use the same browser when completing the Film Registration Form.
                        </div>
                    </div>
                </article> 
                <Snackbar
                    open={sbopen}
                    autoHideDuration={3000}
                    onClose={handleSnackBarClose}
                    message={opMessage}
                    action={sbaction}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                />
            </Box>
        </Box>

    );
}