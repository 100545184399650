import React from 'react';
import Copy from '../FormElements/Copy';
import TextInput from './TextInput';
import CheckBox from './CheckBox';
import DropDown from './DropDown';
import DropDownOther from './DropDownOther';
import TextBox from './TextBox';
import MultiGrid from './MultiGrid';
import MultiCheck from './MultiCheck';
import MultiCheckOther from './MultiCheckOther';
import RoleGrid from './RoleGrid';
import BoolSelect from './BoolSelect';
import DateSelect from './DateSelect';
import RadioButtonList from './RadioButtonList';
import { GetPresentationFormatOptions } from '../Helpers/Helpers';
import { IsConditionalProp, IsConditionalRole, ValidateRoleCondition, ValidatePropertyDisplayCondition } from '../Helpers/Helpers';
import { ValidatePropertyRequiredCondition, ValidateRoleReqCondition } from '../Helpers/Helpers';


export default function FormContentList(props) {

    // these properties must be passed from parent
    const conditional_props = props.conditional_props;
    const conditional_roles = props.conditional_roles;
    const content_elements = props.content_elements;
    const values = props.values;
    const handleChange = props.handleChange;
    const handleChangeMulti = props.handleChangeMulti;
    const handleChangeDDOSel = props.handleChangeDDOSel;
    const handleChangeDDOText = props.handleChangeDDOText;
    const handleChangeCheck = props.handleChangeCheck;   
    const handleChangeTyped = props.handleChangeTyped;
    const handlePrimary = props.handlePrimary;
    const presentation_formats = props.presentation_formats;  
    const requiredarray = props.requiredarray;
    const requiredrolesarray = props.requiredrolesarray;
    const staterolesar = props.staterolesar;
    const filmid = props.filmid;
    const saveSnapshot = props.saveSnapshot;
    //

    const formElements = [];

    // create form element based on type and add to list for rendering below
    if (content_elements.length > 0) {
        content_elements.forEach((citem) => {
            // determine if property is conditional
            const isconditional = IsConditionalProp(citem.object_property, conditional_props);

            // determine if property is required
            let required = false;
            if (requiredarray.includes(citem.object_property)) {
                required = true;
            } else {
                // if property is conditional check the conditional rules                
                if (isconditional) {
                    required = ValidatePropertyRequiredCondition(citem.object_property, conditional_props, values);
                }
            }

            // determine if property must be displayed
            let showprop = true;
            if (isconditional) {
                showprop = ValidatePropertyDisplayCondition(citem.object_property, conditional_props, values);
            }

            // handling of default pre-filled values
            let defvalue = "";
            // do this only if showprop?
            if (showprop) {
                if (citem.default_value) {
                    defvalue = citem.default_value;
                }
                // if values are passed, override default_value if not null/undefined
                // if temp value is empty string it should pass
                if (values) {
                    if (values[citem.object_property] != null) {
                        defvalue = values[citem.object_property];
                    }
                }
            } else {
                // enforce reset value of property in case it has a value
                // save previously
                // commente out for now
                // it does not work

                //if (values) {
                //    if (values[citem.object_property]) {
                //        if (values[citem.object_property] != "") {
                //            // we need to change the filmobj data
                //            // the call below doesn't work (has a warning)
                //            // we need another solution
                //            // maybe run this in parent? (FormList)
                //            handleChangeTyped(citem.object_property, "");
                //        }
                //    }
                //}
            }            

            // custom property to determine location of helper text
            // before or after main control
            let helper_loc = "post";
            if (citem.custom_props) {
                if (citem.custom_props.helper_loc) {
                    helper_loc = citem.custom_props.helper_loc;
                }               
            }

            switch (citem.input_type) {
                case "textinput":
                    const tielement = <TextInput key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChange}
                        label={citem.content_copy} name={citem.object_property}
                        size={citem.input_size} value={defvalue} custom_props={citem.custom_props} />;
                    formElements.push(tielement);
                    break;
                case "textbox":
                    const tbelement = <TextBox key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChange}
                        label={citem.content_copy} name={citem.object_property}
                        size={citem.input_size} value={defvalue} custom_props={citem.custom_props} />;
                    formElements.push(tbelement);
                    break;
                case "dropdown":
                    let ddoptions = [];
                    // special use case for presentation formats, aspect_ratios and sound_formats
                    let ispformat = false;
                    if (citem.options) {
                        if (citem.options.length > 0) {
                            if (citem.options[0].includes(".presentation_formats")) {
                                ispformat = true;
                            }
                        }
                    }
                    if (ispformat) {
                        ddoptions = GetPresentationFormatOptions(citem.options[0], presentation_formats, values);
                    }
                    else {
                        ddoptions = citem.options;
                    }
                    const ddelement = <DropDown key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChange}
                        label={citem.content_copy} name={citem.object_property}
                        value={defvalue} options={ddoptions} custom_props={citem.custom_props} />;
                    formElements.push(ddelement);
                    break;
                case "multigrid":
                    // default value is array in this case                   
                    const mgelement = <MultiGrid key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChangeMulti}
                        label={citem.content_copy} name={citem.object_property}
                        value={defvalue} options={citem.options} custom_props={citem.custom_props} />;
                    formElements.push(mgelement);
                    break;
                case "dropdownother":
                    const ddoelement = <DropDownOther key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChangeDD={handleChangeDDOSel}
                        onChangeOther={handleChangeDDOText} label={citem.content_copy}
                        name={citem.object_property} value={defvalue}
                        options={citem.options} custom_props={citem.custom_props} />;
                    formElements.push(ddoelement);
                    break;
                case "checkbox":
                    const celement = <CheckBox key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChangeCheck}
                        label={citem.content_copy} name={citem.object_property}
                        checked={defvalue} custom_props={citem.custom_props} options={citem.options} />;
                    formElements.push(celement);
                    break;
                case "radiobuttonlist":
                    // split options as array
                    const roptionarr = citem.options.toString().split(",");
                    const radioelement = <RadioButtonList key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChange}
                        label={citem.content_copy} name={citem.object_property} value={defvalue}
                        options={roptionarr} custom_props={citem.custom_props} />;
                    formElements.push(radioelement);
                    break;
                case "multicheck":
                    const mcelement = <MultiCheck key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChangeTyped}
                        label={citem.content_copy} name={citem.object_property} value={defvalue}
                        options={citem.options} custom_props={citem.custom_props} />;
                    formElements.push(mcelement);
                    break;
                case "multicheckother":                    
                    const mcoelement = <MultiCheckOther key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChangeTyped}
                        label={citem.content_copy} name={citem.object_property} value={defvalue}
                        options={citem.options} custom_props={citem.custom_props}
                        helper_loc={helper_loc} />;
                    formElements.push(mcoelement);
                    break;
                case "date":
                    const datelement = <DateSelect key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChangeTyped}
                        label={citem.content_copy} name={citem.object_property}
                        value={defvalue} custom_props={citem.custom_props} />;
                    formElements.push(datelement);
                    break;
                case "boolean":
                    // split options as array
                    const optionarr = citem.options.toString().split(",");
                    const boolelement = <BoolSelect key={citem.content_key} show={showprop}
                        helper={citem.content_helper} req={required} onChange={handleChange}
                        label={citem.content_copy} name={citem.object_property} value={defvalue}
                        options={optionarr} custom_props={citem.custom_props} />;
                    formElements.push(boolelement);
                    break;
                case "rolegrid":
                    // required rules are different for roles
                    const roleidint = parseInt(citem.object_property);
                    const isconditionalRole = IsConditionalRole(roleidint, conditional_roles);
                    required = false;
                    const roleid = parseInt(citem.object_property);
                    if (requiredrolesarray.includes(roleid)) {
                        required = true;
                    } else {
                        if (isconditionalRole) {
                            required = ValidateRoleReqCondition(roleidint, conditional_roles, values);
                        }
                    }
                    const filteredroles = staterolesar.filter(function (roleitem) {
                        return roleitem.roleid === citem.object_property;
                    });
                    // determine if property must be displayed
                    let showrole = true;
                    if (isconditionalRole) {
                        showrole = ValidateRoleCondition(roleidint, conditional_roles, values);
                    }
                    // parse rolePrimaries if present in film object
                    let rolePrimaries = [];
                    if (values["rolePrimaries"]) {
                        rolePrimaries = values["rolePrimaries"];
                    }
                    const rgelement = <RoleGrid key={citem.content_key} show={showrole}
                        roleid={citem.object_property} helper={citem.content_helper}
                        req={required} header={citem.content_copy} name={citem.object_property}
                        roledata={filteredroles} sectionid={props.section_id} filmid={filmid}
                        custom_props={citem.custom_props} rolePrimaries={rolePrimaries}
                        onPrimary={handlePrimary} saveSnapshot={saveSnapshot }
                    />;
                    formElements.push(rgelement);
                    break;
                case "label":
                    let classname = "";
                    if (props.custom_props) {
                        if (props.custom_props.classname) {
                            classname = props.custom_props.classname;
                        }
                    } 
                    const copyitem = <Copy columns={2} key={citem.content_key}
                        header={citem.content_copy} show={showprop}
                        value={citem.content_helper} id={citem.content_key}
                        custom_props={citem.custom_props} classname={classname}
                        />
                    formElements.push(copyitem);
                    break;
                default:
                    break;
            }
        })
    }    

    return (formElements);
}