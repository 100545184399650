import React, { useState, useEffect } from 'react';
import { GetCompletionStatus } from '../ApiClient/ApiClient';
import SectionStatus from "./SectionStatus";
import useSession from '../../useSession';
import Grid from "@mui/material/Grid";
import MainNav from '../MainNav/MainNav';

function ParseQs(qsname) {
    let params = (new URL(document.location)).searchParams;
    let qsvalue = "";
    if (params.has(qsname)) {
        qsvalue = params.get(qsname);
    }
    return qsvalue;
} 

export default function Status() {

    const { sessionItem } = useSession();
    const final = sessionItem.film.submittedfinal;
     
    let sectionid = 1;
    const qssection = ParseQs("sectionid");
    if (qssection) {
        sectionid = qssection;
    }    
          
    const [opMessage, setOpMessage] = useState("");
    const [statusData, setStatusData] = useState();

    // call API hook
    useEffect(() => {  
        let sectionid = 1;
        const qssection = ParseQs("sectionid");
        if (qssection) {
            sectionid = qssection;
        }
        const credentials = sessionItem.accesstoken;
        const filmid = sessionItem.film.filmObjId;
        const formid = sessionItem.form.form_id;
        const getStatusData = async () => {            
            const respo = await GetCompletionStatus(credentials, filmid, formid, sectionid);
            if (respo.film_id && respo.film_title) {
                setStatusData(respo);               
            } else {
                // handle error             
                setOpMessage("Unexpected error while loading the data for this page.")                
            }
        };
        getStatusData();
    }, [sessionItem.accesstoken, sessionItem.film.filmObjId, sessionItem.form.form_id]);
    // end of async call logic

    if (statusData) {
        // pass API response to Section Status Component
        let details = [];
        let completed = false;
        if (statusData.details) {
            details = statusData.details;           
        }
        // generate status message
        if (statusData.completed) {
            completed = true;            
        }
        // render SectionStatus Component that displays the completion status of a form section
        return (<div>
                  <div>
                     <MainNav />
                  </div>
                    <SectionStatus details={details} completed={completed} sectionid={sectionid} final={final} />
                  </div>)
    } 

    return (<Grid container spacing={1} >
                <Grid item xs={12}>
                    {opMessage}
                 </Grid >    
            </Grid>);
}