import React, { useState, useEffect } from 'react';
import { GetCompletionStatus } from '../ApiClient/ApiClient';
import SectionStatus from "../Status/SectionStatus";
import useSession from '../../useSession';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MainNav from '../MainNav/MainNav';
import { SubmitFinalFilm } from '../ApiClient/ApiClient';
import { useNavigate } from "react-router-dom";

export default function SubmitFinal() {

    const { sessionItem, setSession } = useSession();

    const [opMessage, setOpMessage] = useState("");
    const [statusData, setStatusData] = useState();

    if (sessionItem.film.submittedfinal) {
        // if film is submitted final
        // redirect to dashboard
        // this page should not be displayed
        window.location.href = '/';        
    }

    let navigate = useNavigate(); 
   

    // call API hook
    useEffect(() => {
        let sectionid = 0;
        const credentials = sessionItem.accesstoken;
        const filmid = sessionItem.film.filmObjId;
        const formid = sessionItem.form.form_id;
        const getStatusData = async () => {
            const respo = await GetCompletionStatus(credentials, filmid, formid, sectionid);
            if (respo.film_id && respo.film_title) {
                setStatusData(respo);
            } else {
                // handle error             
                setOpMessage("Unexpected error while loading the data for this page.")
            }
        };
        getStatusData();
    }, [sessionItem.accesstoken, sessionItem.film.filmObjId, sessionItem.form.form_id]);
    // end of async call logic

    const statuslist = [];

    let filmcomplete = false;
    let filmtitle = "";

    if (statusData) {
        // break API response into sections
        // get number of sections and create a for loop
        const sectioncount = sessionItem.form.form_sections.length;
        for (let i = 1; i <= sectioncount; i++) {
            const section_id = i;
            const filtered_details = statusData.details.filter(function (detail) {
                return detail.section_id === i;
            });
            let sectioncomplete = true;
            if (Array.isArray(filtered_details)) {
                filtered_details.forEach((fdetail) => {
                    if (!fdetail.completed) {
                        sectioncomplete = false;
                    }
                });
            }
            statuslist.push(<SectionStatus key={"sectionstatus_" + i.toString()} details={filtered_details} completed={sectioncomplete} sectionid={section_id} opMessage={opMessage} />)
        }

        // generate status message
        if (statusData.completed) {
            filmcomplete = true;
        }
        filmtitle = statusData.film_title;    

        if (!filmcomplete) {
            // make sure that film is complete
            // if not redirect to /print page to review status
            window.location.href = '/print';            
        }
    }


    // handle Submit Final action
    async function SubmitFinalAction() {
        const filmid = sessionItem.film.filmObjId;  
        const credentials = sessionItem.accesstoken;
        const addrespo = await SubmitFinalFilm(credentials, filmid);
        if (addrespo.IsError) {
            // handle error
            // Handle error
            setOpMessage("Operation Error: " + addrespo.Message);            
        } else { 
            // update sessionState with submitfinal flag
            sessionItem.film.submittedfinal = true;
            // save sessionItem
            setSession(sessionItem);
            setOpMessage("This Film has been submitted final.");  
            navigate("/", { replace: true }); 
        }
    }   

    return (<div>
        <MainNav />
        <Box sx={{ marginTop: 1 }}>
            {filmcomplete && 
                <Grid item xs={12} sx={{backgroundColor:'#f0f0f0', p:1}} >
                    <Box sx={{ fontSize: 'h6.fontSize', marginTop: 1, fontWeight: 'bold' }}>                    
                        The invitations form for the film: {filmtitle}, is completed.                    
                    </Box>
                    <Box>
                        <p>
                            <strong>To submit your form, please scroll to the bottom of this screen and
                            click the blue "Submit film" button</strong>.
                        </p>
                        <p>
                            This form may be submitted once. If amendments to the information supplied are required, they must be sent
                            directly to Festival staff via email.
                        </p>
                        <p>
                            After clicking "Submit film," you will see a message indicating that the
                            operation has been completed successfully.
                        </p>
                    </Box>
                </Grid>}
            {!filmcomplete &&
                <Grid item xs={12} >
                    <Box sx={{ fontSize: 'h6.fontSize', marginTop: 1, padding:1, fontWeight: 'bold', backgroundColor:'#f0f0f0' }}>
                        Data entry is not completed for the film: {filmtitle}
                    </Box>                   
                </Grid>}
            {statuslist}
            {filmcomplete &&
                <Grid item xs={12} sx={{marginTop:2}}>
                    <Button variant="contained" name="submitfinalbtn" onClick={SubmitFinalAction}>
                        Submit Film
                    </Button>
                </Grid >
            }
            <Grid item xs={12}>
                {opMessage}
            </Grid >
        </Box>
    </div>);
}