import MainNav from '../MainNav/MainNav';

export default function About() {
    return (
        <div>  
            <MainNav /> 
            <h2>About this form</h2>
            <div>
                <p>
                    We ask that you complete and submit this form within 48 hours of receiving your Official Invitation Package.
                </p>
                <p>
                    This form may be submitted only once. It can, however, be saved as a work in progress by clicking the save button found at the bottom of each page before logging out. Please save each page as you go.
                </p>
                <p>
                    If you have any questions, please contact <a className="lightblue" href="mailto:festivalinvitations@tiff.net">festivalinvitations@tiff.net</a>
                </p>
            </div>
        </div>        
    );
}