import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { loginUser } from '../ApiClient/ApiClient'


export default function Login({ setSession }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errmsg, setErrMessage] = useState();

    const curyear = new Date().getFullYear();

    const handleSubmit = async e => {
        e.preventDefault();

        // clean previous messages
        setErrMessage("");

        const respo = await loginUser(username, password);

        if (respo.accesstoken) {
            // save API data in sessionStorage
            setSession(respo);
        } else {
            // case for invalid login
            // display message
            setErrMessage("Invalid Login attempt.");

            // clear input
            setUserName("");
            setPassword("");
            document.getElementById("loginid").value = "";
            document.getElementById("loginpwd").value = "";
        }         
          
    }

    return (
        <div className="login-wrapper">
            <div>
                <img src="/img/TIFF_logo_blk_TM-01_200.png" alt="TIFF Film Invitations 2022"/>
            </div>
            <h1>Selected Film Registration</h1>
            Please fill in the film code and password you received with your invitation email.
            <form onSubmit={handleSubmit}>

            <div className="login-box">
                <label>
                    <p>Username (Film Code)</p>
                    <input type="text" id="loginid" name="username" onChange={e => setUserName(e.target.value)} />
                </label>
                <label>
                    <p>Password</p>
                <input type="password" id="loginpwd" name="password" onChange={e => setPassword(e.target.value)} />
                 </label>
                    <div className="login-submit">
                        <Button variant="outlined" type="submit" sx={{
                        color: 'black',
                        borderColor: 'black'
                        }}>Log in</Button>
                    </div>  
                </div>
                <div className="err-message">
                    <p>{errmsg}</p>
                </div>
                
            </form>
            <Box
                component="img"
                sx={{                    
                    width: 900,                    
                    minWidth: { xs: 400 },
                }}
                alt="tiff sponsors"
                src="/img/Festival-Public-logobar-blk.png"
            />  
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 12,
                marginTop: 5,
                marginBottom: 3
            }}>
                &copy; {curyear} Toronto International Film Festival. All rights reserved.                
            </Box>
        </div>
    )
}

Login.propTypes = {
    setSession: PropTypes.func.isRequired
}